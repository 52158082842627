import { addDoc, collection, Timestamp } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { db, storage } from "../firebase";

export default function BlogsPostAdd() {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    image: "",
    comment: "",
    view: "",
    admin: "",
    url: "",
    createdAt: Timestamp.now().toDate(),
  });

  const [progress, setProgress] = useState(0);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const handlePublish = () => {
    if (
      !formData.title ||
      !formData.description ||
      !formData.image ||
      !formData.comment ||
      !formData.view ||
      !formData.admin ||
      !formData.url
    ) {
      toast("All fields are required !", { type: "info" });
      return;
    }

    const storageRef = ref(
      storage,
      `/BlogsPost/${Date.now()}${formData.image.name}`
    );
    const uploadImage = uploadBytesResumable(storageRef, formData.image);

    uploadImage.on(
      "state_changed",
      (snapshot) => {
        const progressPresent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progressPresent);
      },
      (err) => {
        console.log(err);
      },
      () => {
        setFormData({
          title: "",
          description: "",
          image: "",
          comment: "",
          view: "",
          admin: "",
          url: "",
        });
        getDownloadURL(uploadImage.snapshot.ref).then((url) => {
          const articleRef = collection(db, "BlogsPost");
          addDoc(articleRef, {
            title: formData.title,
            description: formData.description,
            comment: formData.comment,
            view: formData.view,
            admin: formData.admin,
            url: formData.url,
            image: url,
            createdAt: Timestamp.now().toDate(),
          })
            .then(() => {
              toast("Your Blogs is Publish.", { type: "success" });
              setProgress(0);
            })
            .catch((err) => {
              toast("Error found in adding", { type: "error" });
            });
        });
      }
    );
  };
  return (
    <div className="border p-3 bg-white mt-5">
      <h4>Create Blogs</h4>
      <label htmlFor="">Title</label>
      <input
        type="text"
        name="title"
        className="form-control"
        value={formData.title}
        onChange={(e) => handleChange(e)}
      />

      <label htmlFor="">Description</label>
      <textarea
        name="description"
        className="form-control"
        value={formData.description}
        onChange={(e) => handleChange(e)}
      ></textarea>

      <label htmlFor="">Url</label>
      <input
        type="text"
        name="url"
        className="form-control"
        value={formData.url}
        onChange={(e) => handleChange(e)}
      />

      <div className="d-flex">
        {/* <label htmlFor="">Comment</label> */}
        <i className="fas fa-comments m-auto pr-2"></i>
        <input
          type="number"
          name="comment"
          className="form-control m-auto"
          value={formData.comment}
          onChange={(e) => handleChange(e)}
        />
        {/* <label htmlFor="">Views</label> */}
        <i className="fas fa-eye m-auto px-2"></i>
        <input
          type="number"
          name="view"
          className="form-control m-auto"
          value={formData.view}
          onChange={(e) => handleChange(e)}
        />
        {/* <label htmlFor="">Admin</label> */}
        <i className="fas fa-user m-auto px-2"></i>
        <input
          type="text"
          name="admin"
          className="form-control m-auto"
          value={formData.admin}
          onChange={(e) => handleChange(e)}
        />
      </div>

      <br />
      <label htmlFor="">Image</label>
      <input
        type="file"
        name="image"
        accept="image/*"
        // value={formData.image}
        className=""
        onChange={(e) => handleImageChange(e)}
      />

      <br />

      {progress === 0 ? null : (
        <div className="progress mt-3">
          <div
            className="progress-bar progress-bar-striped"
            style={{ width: `${progress}%` }}
          >
            {`uploading image ${progress}%`}
          </div>
        </div>
      )}

      <br />
      <button
        className="btn btn-primary my-4 px-5 col-12"
        onClick={handlePublish}
      >
        Publish
      </button>
      <br />
    </div>
  );
}
