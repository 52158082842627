import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import DeleteGallery from "./DeleteGallery";

export const GalleryView = () => {
  const [GalleryView, setGalleryView] = useState([]);
  useEffect(() => {
    const GalleryViewRef = collection(db, "Gallery");
    const q = query(GalleryViewRef, orderBy("createdAt", "desc"));
    onSnapshot(q, (snapshot) => {
      const GalleryView = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setGalleryView(GalleryView);
      console.log(GalleryView);
    });
  }, []);
  return (
    <>
      <div className="mt-5">
        <h3>Add Gallery</h3>
      </div>

      {GalleryView.length === 0 ? (
        <p className="p-5">
          <div class="spinner-border m-auto" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </p>
      ) : (
        <div className="scroll-x mt-5 d-flex">
          {GalleryView.map(({ id, image, url }) => (
            <div className="col-md-4" key={id}>
              <img
                className="img-thumbnail"
                src={image}
                alt="images"
                width="500"
                height="500"
              />
              <div className="py-4 my-1">
                <DeleteGallery id={id} image={image} />
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
