import { deleteDoc, doc } from "firebase/firestore";
import { deleteObject, ref } from "firebase/storage";
import React from "react";
import { toast } from "react-toastify";
import { db, storage } from "../firebase";

export default function DeleteBlog({ id, image }) {
  const handleDelete = () => {
    try {
      deleteDoc(doc(db, "BlogsPost", id));
      toast("Blogs Post Deleted", { type: "success" });
      const storageRef = ref(storage, image);
      deleteObject(storageRef);
    } catch (error) {
      toast("Error item not delete", { type: "error" });
      console.log(error);
    }
  };
  return (
    <div>
      <button className="btn btn-danger" onClick={handleDelete}>
        Delete
      </button>
    </div>
  );
}
