import React from "react";
import { useEffect, useState } from "react";
import fireDb from "./firebase";
import { toast } from "react-toastify";

const Feedback = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    fireDb.child("feedback").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setData({ ...snapshot.val() });
      } else {
        setData({});
      }
    });
    return () => {
      setData({});
    };
  }, []);

  const onDelete = (id) => {
    if (window.confirm("Are you sure that you wanted to delete feedback ?")) {
      fireDb.child(`feedback/${id}`).remove((err) => {
        if (err) {
          toast.error(err);
        } else {
          toast.success("Feedback Deleted !");
        }
      });
    }
  };

  return (
    <>
      <div className="container my-5 pb-5 scroll-y">
        <table className="table table-striped styled-table border table-responsive d-table">
          <thead className="bg-info text-white">
            <tr className="text-center">
              <th scope="col">No.</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Mobile</th>
              <th scope="col">Message</th>
              <th scope="col">Delete</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(data).map((id, index) => {
              return (
                <tr key={id} className="text-center">
                  <th scope="row">{index + 1}</th>
                  <td>{data[id].name}</td>
                  <td>{data[id].email}</td>
                  <td>{data[id].phone}</td>
                  <td>{data[id].message}</td>
                  <td>
                    <button
                      className="btn btn-delete mx-1"
                      onClick={() => onDelete(id)}
                    >
                      <i class="fa fa-trash text-danger" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Feedback;
