import React from "react";
import Footer from "./Footer";
import Header from "./Header";

// function About() {
const About = () => {
  return (
    <>
    <Header />
      <div className="container pb-4 mb-4">
        <div className="row-fluid">
          <div className="wpcf7 entry-content page-heading">
            <h1 className="wpcf7 entry-content h1 text-success">
              {/* About Hospital */}
            </h1>
            <div className="toolbar pt-2">
              <div className="breadcrumb">
                <span className="crumbs">
                  <a title="Home" href="/">
                    <i className="fas fa-home pr-1"></i>
                    Home
                  </a>
                  <i className="fas fa-angle-double-right px-1"></i>
                  <a title="About" href="/#about_us">
                    About us
                  </a>
                  <i className="fas fa-angle-double-right px-1"></i>
                  About Hospital
                </span>
              </div>
            </div>
          </div>
          <div className="">
            <h3 className="text-primary">About Our Health Care Center</h3>
            <p className="text-justify">
              <strong>Sunni Mohalla Clinic</strong> is a charitable,
              multi-specialty hospital located at Raza Jama Masjid Ke Pass
              Roshan Bag Kharbi Nagpur India. Established in 14 April, 2021 &
              today it is a best charitable hospital in our society with there
              Branches. Khidmat Foundation Hospital offers state-of-the-art
              diagnostic, therapeutic and intensive care facilities in a
              one-stop medical center. Health Care Center has developed its
              Maternity and Gynaecology services to meet the needs of local
              women and their families. We provide a tertiary (specialised care)
              service for women with specialist needs in fetal/maternal medicine
              and we are a regional centre for Gynaecology. We also provide a
              community midwifery service and in some local health clinics
              provide family planning services.
            </p>
            <br />

            <h3 className="text-primary">Our Mission & Vision</h3>
            <p className="text-justify">
              <strong>Khidmat Foundation</strong> is a Social Organization whose
              aim's to serve the poor people and helps them. Our NGO has been
              working since 6 years and at last year 2020, Khidmat Foundation
              doing more social works in our Society. We provide facility for
              poor people to take care there family at least money so that they
              can take advantage of it. If you also want to join Khidmat
              Foundation then you can become a member of Khidmat Foundation by
              giving your precious time.
            </p>
            <p className="text-justify">
              We will sincerely work together as a team to make Sunni Mohalla
              Clinic Hospital a medical centre of international reputation. We
              will provide excellent health education pre and post hospital
              care. We will not discriminate between rich and poor with regard
              to medical treatment.
            </p>
            <br />

            <p className="text-center">
              <i>
                "We are committed to medico-social services"
                <br />
                "To provide competent, ethical, tertiary healthcare services
                with charity as a core value"
                <br />
                "To provide Rational Ethical Medical Services of Highest Quality
                to all Patients at affordable cost without any discrimination"
                <br />
                "We believes in highest professional competence with a human
                touch and will deliver excellent medical treatment to everyone"
              </i>
            </p>

            <br />

            <h3 className="text-primary">Available Facilities</h3>
            <ul className="list">
              <li>
                {" "}
                <i className="far fa-arrow-alt-circle-right"> </i> Tuboplasty &
                Tubectomy
              </li>
              <li>
                {" "}
                <i className="far fa-arrow-alt-circle-right"> </i>{" "}
                Pre-conception Counseling
              </li>
              <li>
                {" "}
                <i className="far fa-arrow-alt-circle-right"> </i> Pregnancy &
                Related Problems
              </li>
              <li>
                {" "}
                <i className="far fa-arrow-alt-circle-right"> </i> Family
                Planning & Counseling
              </li>
              <li>
                {" "}
                <i className="far fa-arrow-alt-circle-right"> </i> Sonography &
                Antenatal Screening
              </li>
              <li>
                {" "}
                <i className="far fa-arrow-alt-circle-right"> </i> Cesarean
                Section & Normal Delivery
              </li>
              <li>
                {" "}
                <i className="far fa-arrow-alt-circle-right"> </i> Female
                Genital Disease & Pap Smear
              </li>
              <li>
                {" "}
                <i className="far fa-arrow-alt-circle-right"> </i>{" "}
                Gynaecological Problems & STD Testing
              </li>
              <li>
                {" "}
                <i className="far fa-arrow-alt-circle-right"> </i> Female
                Infertility & Intra Uterine Insemination
              </li>
            </ul>
            <br />

            <h3 className="text-primary">Core Values</h3>
            <ul className="list">
              <li>
                {" "}
                <i className="far fa-arrow-alt-circle-right"> </i>{" "}
                Patient-centric Care
              </li>
              <li>
                {" "}
                <i className="far fa-arrow-alt-circle-right"> </i> Rational
                &amp; Ethical Medical Practice
              </li>
              <li>
                {" "}
                <i className="far fa-arrow-alt-circle-right"> </i> Holistic
                Approach
              </li>
              <li>
                {" "}
                <i className="far fa-arrow-alt-circle-right"> </i> Charity
              </li>
            </ul>
            <br />
            <h3 className="text-primary">Blood Test</h3>
            <ul className="list">
              <li>
                {" "}
                <i className="far fa-arrow-alt-circle-right"> </i> WIDAL SLIDE{" "}
              </li>
              <li>
                {" "}
                <i className="far fa-arrow-alt-circle-right"> </i> BLOOD GROUP{" "}
              </li>
              <li>
                {" "}
                <i className="far fa-arrow-alt-circle-right"> </i> URINE ROUTIN{" "}
              </li>
              <li>
                {" "}
                <i className="far fa-arrow-alt-circle-right"> </i> DENGUE
                PROFILE{" "}
              </li>
              <li>
                {" "}
                <i className="far fa-arrow-alt-circle-right"> </i> MALARIA
                ANTIGEN{" "}
              </li>
              <li>
                {" "}
                <i className="far fa-arrow-alt-circle-right"> </i> HB
                Electrophoresis{" "}
              </li>
              <li>
                {" "}
                <i className="far fa-arrow-alt-circle-right"> </i> HBs Ag.{" "}
              </li>
              <li>
                {" "}
                <i className="far fa-arrow-alt-circle-right"> </i> CBC, CRP, ESR{" "}
              </li>
              <li>
                {" "}
                <i className="far fa-arrow-alt-circle-right"> </i> LFT, KFT, TFT{" "}
              </li>
              <li>
                {" "}
                <i className="far fa-arrow-alt-circle-right"> </i> TSH, T3, T4{" "}
              </li>
              <li>
                {" "}
                <i className="far fa-arrow-alt-circle-right"> </i> HIV I & II{" "}
              </li>
            </ul>
            <br />
            <h3 className="text-primary">Social Activity</h3>
            <p className="text-justify">
              Khidmat Foundation has been working since 6 years and at last year
              2020, Khidmat Foundation doing more social works in our Society.
            </p>
            <p className="text-justify">
              Our NGO organised to providing help in our society we are serving
              duties and helping our needy peoples.
            </p>
            <p className="text-justify">
              We provide facility for poor people to take care there family at
              least money so that they can take advantage of it.
            </p>
            <p className="text-justify">
              We are providing facility of Experienced General Physicians and
              Experienced Gynaecologist to the local ward.
            </p>
            <p className="text-justify">
              We are donating medicines in Health Check-Up camp in free of cost
              so everyone can take advantage of it.
            </p>
            <p className="text-justify">
              We also provide a community midwifery service and in some local
              health clinics provide family planning services.
            </p>
            <p className="text-justify">
              Our Medical Staff are giving their precious time to our Hospital
              so that they can help the poor and needy people.
            </p>
            <p className="text-justify">
              Due to COVID-19, In this Pandemic Khidmat Foundation provide
              Rashan Kit, Clothes & give money to needy ones.
            </p>
            <p className="text-justify">
              In the COVID-19 period we have decided to open a hospital for
              needy people Only in <i className="fa">20 &#xf156; </i> fees.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default About;
