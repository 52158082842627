import React from "react";

function Services() {
  return (
    <>
      <section id="services" className="key-features">
        <div className="container">
          <div className="inner-title">
            <h2 className="text-primary">Speciality Service</h2>
            <p className="text-success">
              We have several Health Check-up packages{" "}
            </p>
          </div>

          <div className="row">
            <div className="col-md-4 col-sm-6">
              <div className="single-key">
                <i className="fas fa-hospital-alt"></i>
                <h5>New Branches </h5>
                <p>
                  Our aim is that in future we will open 26<sup>th</sup> new
                  branches of Sunni Mohalla Clinic in Nagpur city.
                </p>
              </div>
            </div>

            <div className="col-md-4 col-sm-6">
              <div className="single-key">
                <i className="fas fa-user-md"></i>
                <h5>Experienced Doctors</h5>
                <p>
                  We are providing facility of Experienced General Physicians
                  and Experienced Gynaecologist to the local ward.
                </p>
              </div>
            </div>

            <div className="col-md-4 col-sm-6">
              <div className="single-key">
                <i className="fas fa-briefcase-medical"></i>
                <h5>High Quality Treatments</h5>
                <p>
                  Cesarean section and Normal delivery, Female Genital Disease
                  and Pap Smear, Gynaecological related problems.
                </p>
              </div>
            </div>

            <div className="col-md-4 col-sm-6">
              <div className="single-key">
                <i className="fas fa-capsules"></i>
                <h5>Free Medicines</h5>
                <p>
                  We are donating medicines in Health Check-Up camp in free of
                  cost so everyone can take advantage of it.
                </p>
              </div>
            </div>

            <div className="col-md-4 col-sm-6">
              <div className="single-key">
                <i className="fas fa-prescription-bottle-alt"></i>
                <h5>Medical Team</h5>
                <p>
                  Our Medical Staff are giving their precious time to our
                  Hospital so that they can help the poor and needy people.
                </p>
              </div>
            </div>

            <div className="col-md-4 col-sm-6">
              <div className="single-key">
                <i className="far fa-thumbs-up"></i>
                <h5>Patients Review</h5>
                <p>
                  Patients are fully Satisfied with our Doctors, Medical Staff,
                  Facilities and Hospital's Infrastructure.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Services;
