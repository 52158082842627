import React from "react";
import { Link } from "react-router-dom";

function Header() {
  return (
    <>
      <header id="menu-jk">
        <div id="nav-head" className="header-nav">
          <div className="container">
            <div className="row">
              <div className="col-lg-2 col-md-3 no-padding col-sm-12 nav-img">
                <img src="assets/images/kflogo.jpg" alt="" />
                <a
                  //   data-toggle="collapse"
                  href="#menu"
                  id="modal_view_right"
                  data-toggle="modal"
                  data-target="#menu"
                >
                  <i className="fas d-block d-md-none small-menu fa-bars text-info"></i>
                </a>
              </div>
              <div
                // id="menu"
                className="col-lg-8 col-md-9 d-none d-md-block nav-item"
              >
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/#services">Services</a>
                  </li>
                  <li>
                    <a href="/#about_us">About Us</a>
                  </li>
                  <li>
                    <a href="/#blog">Blog</a>
                  </li>
                  <li>
                    <a href="/#gallery">Gallery</a>
                  </li>
                  <li>
                    <a href="/#contact_us">Contact Us</a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-2 d-none d-lg-block appoint">
                {/* <button
                  data-toggle="modal"
                  data-target="#myModal"
                  className="btn btn-success"
                >
                  Donate Now
                </button> */}
                {/* <!-- This Option use in future --> */}
                <Link target={"_blank"} to="/appointment" className="btn btn-success">
                  Book an Appointment
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div
        className="modal modal_outer right_modal fade"
        id="menu"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel2"
      >
        <div className="modal-dialog" role="document">
          <form method="post" id="get_quote_frm">
            <div className="modal-content ">
              <div className="modal-header">
                <img src="assets/images/kflogo.jpg" alt="" />
                <button
                  type="button"
                  className="close p-0"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body get_quote_view_modal_body">
                <div className="col-lg-8 col-md-9 d-md-block nav-item">
                  <ul>
                    <li className="text-center">
                      <a href="/">Home</a>
                    </li>
                    <hr />
                    <li className="text-center">
                      <a href="/#services">Services</a>
                    </li>
                    <hr />
                    <li className="text-center">
                      <a href="/#about_us">About Us</a>
                    </li>
                    <hr />
                    <li className="text-center">
                      <a href="/#blog">Blogs</a>
                    </li>
                    <hr />
                    <li className="text-center">
                      <a href="/#gallery">Gallery</a>
                    </li>
                    <hr />
                    <li className="text-center">
                      <a href="/#contact_us">Contact Us</a>
                    </li>
                    <hr />
                    <li className="text-center">
                      <a href="/#" data-toggle="modal" data-target="#myModal">
                        Donate Now
                      </a>
                    </li>
                    <hr />
                    <li className="text-center">
                      <a href="/appointment">Book Appointment</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="modal-footer center">
                <span>Follow us</span>
                <span className="px-2">
                  <a
                    className="px-3 justify-content-between"
                    href="https://www.facebook.com/Khidmatfoundationnagpur/"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a
                    className="px-3 justify-content-between"
                    href="https://www.instagram.com/khidmat_foundation_nagpur/"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a
                    className="px-3 justify-content-between"
                    href="https://www.youtube.com/channel/UC2ayb2ROklHGbUvbNyaWhcw"
                  >
                    <i className="fab fa-youtube"></i>
                  </a>
                  <a
                    className="px-3 justify-content-between"
                    href="https://chat.whatsapp.com/C0moBvA2Dh7Ff9nkd7bTj8"
                  >
                    <i className="fab fa-whatsapp"></i>
                  </a>
                  <a
                    className="px-3 justify-content-between"
                    href="https://www.twitter.com/Khidmatfoundationnagpur/"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Header;
