import React from "react";

function Footer() {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <h2>About Us</h2>
              <p>
                Khidmat Foundation is a Social Organization whose aim's to serve
                the poor people and helps them. Our NGO has been working since 6
                years and at last year 2020, Khidmat Foundation doing more
                social works in our Society. We provide facility for poor people
                to take care there family at least money so that they can take
                advantage of it. If you also want to join Khidmat Foundation
                then you can become a member of Khidmat Foundation by giving
                your precious time.
              </p>
            </div>
            <div className="col-md-4 col-sm-12">
              <h2>Useful Links</h2>
              <ul className="list-unstyled link-list">
                <li>
                  <a ui-sref="about" href="/about">
                    About Our Health Care Center
                  </a>
                  <i className="fa fa-angle-right"></i>
                </li>
                <li>
                  <a
                    ui-sref="products"
                    href="https://www.khidmat-foundation.com/education"
                  >
                    Khidmat Education Library
                  </a>
                  <i className="fa fa-angle-right"></i>
                </li>
                <li>
                  <a
                    ui-sref="portfolio"
                    href="https://www.khidmat-foundation.com"
                  >
                    Visit Our NGO Website
                  </a>
                  <i className="fa fa-angle-right"></i>
                </li>
                <li>
                  <a
                    ui-sref="gallery"
                    href="https://www.khidmat-foundation.com/langar-e-chistiya"
                  >
                    Langar e Chistiya
                  </a>
                  <i className="fa fa-angle-right"></i>
                </li>
                <li>
                  <a
                    ui-sref="contact"
                    href="https://www.khidmat-foundation.com/join-us"
                  >
                    Join us
                  </a>
                  <i className="fa fa-angle-right"></i>
                </li>
              </ul>
            </div>
            <div className="col-md-4 col-sm-12 map-img">
              <h2>Contact Us</h2>
              <address className="md-margin-bottom-40">
                Khidmat Foundation <br />
                {/* <!-- Main Office Address >> <br /> --> */}
                Sunni Mohalla Clinic <br />
                Raza Jama Masjid Ke Pass
                <br />
                Roshan bagh Kharbi Road <br />
                Nagpur Mh. India - 440034 <br />
                <hr className="text-center w-75 bg-white" />
                <i className="fa fa-globe"> </i>{" "}
                <a href="https://www.sunnimohallaclinic.com" className="">
                  {/* www.khidmat-foundation.com */}
                  www.sunnimohallaclinic.com
                </a>
                <br />
                <i className="fa fa-phone"></i> +91 7378500800, +91 7378600800{" "}
                <br />
                <i className="fa fa-envelope"> </i>{" "}
                <a href="mailto:nagpurkhidmatfoundation@gmail.com" className="">
                  nagpurkhidmatfoundation@gmail.com
                </a>
              </address>
            </div>
          </div>
        </div>
      </footer>

      <div className="copy">
        <div className="container pb-md-0 pb-3 text-md-left text-center">
          <a href="https://www.SarfarazTech.com/">
            {" "}
            &copy; 2020 - {new Date().getFullYear()} Khidmat Foundation All
            Rights Reserved by SarfarazTech.Com
          </a>

          <span className="px-3">
            <a
              title="Facebook"
              href="https://www.facebook.com/Khidmatfoundationnagpur/"
            >
              <i className="fab fa-facebook-f px-2"></i>
            </a>
            <a
              title="Instagram"
              href="https://www.instagram.com/khidmat_foundation_nagpur/"
            >
              <i className="fab fa-instagram px-2"></i>
            </a>
            <a
              title="Youtube"
              href="https://www.youtube.com/channel/UC2ayb2ROklHGbUvbNyaWhcw"
            >
              <i className="fab fa-youtube px-2"></i>
            </a>
            <a
              title="WhatsApp"
              href="https://chat.whatsapp.com/C0moBvA2Dh7Ff9nkd7bTj8"
            >
              <i className="fab fa-whatsapp px-2"></i>
            </a>
            <a
              title="Twitter"
              href="https://www.twitter.com/Khidmatfoundationnagpur/"
            >
              <i className="fab fa-twitter px-2"></i>
            </a>
          </span>
        </div>
      </div>

      {/* paymeny form */}

      <div
        style={{ zIndex: "999999", marginTop: "4%" }}
        className="modal fade"
        id="myModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
      >
        <div style={{ width: "100%" }} className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myModalLabel">
                Donate Now
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              style={{ padding: "0 20px 20px 20px", color: "rgb(0, 0, 0)" }}
              className="modal-body"
            >
              <h3 style={{ color: "rgba(13, 76, 80, 0.877)" }}>
                <b>Khidmat Foundation Nagpur</b>
              </h3>
              <b>Bank Name : HDFC BANK</b>
              <br />
              <b>Branch : Wardhman Nagar</b>
              <br />
              <b>IFSC Code : HDFC0000502</b>
              <br />
              <b>Account No.: 50200056526721</b>
              <br />
              <img
                className="img-responsive"
                style={{ maxHeight: "50%" }}
                src="./assets/images/barcode.png"
                alt="..."
                srcSet=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
