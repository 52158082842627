import React from "react";
import { useEffect, useState } from "react";
import fireDb from "./firebase";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const Awd = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    fireDb.child("appointment").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setData({ ...snapshot.val() });
      } else {
        setData({});
      }
    });
    return () => {
      setData({});
    };
  }, []);

  const onDelete = (id) => {
    if (
      window.confirm("Are you sure that you wanted to delete appointment ?")
    ) {
      fireDb.child(`appointment/${id}`).remove((err) => {
        if (err) {
          toast.error(err);
        } else {
          toast.success("Appointment Deleted !");
        }
      });
    }
  };

  return (
    <>
      <div className="container my-5 pb-5 scroll-y">
        <table className="table table-striped styled-table border table-responsive">
          <thead className="bg-info text-white">
            <tr className="text-center">
              <th scope="col">No.</th>
              <th scope="col">Name</th>
              <th scope="col">Contact</th>
              <th scope="col">Email</th>
              <th scope="col">Date</th>
              <th scope="col">Time</th>
              <th scope="col">Message</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(data).map((id, index) => {
              return (
                <tr key={id} className="text-center">
                  <th scope="row">{index + 1}</th>
                  <td>
                    {data[id].name} {data[id].sirname}
                  </td>
                  <td>{data[id].contact}</td>
                  <td>{data[id].email}</td>
                  <td>{data[id].date}</td>
                  <td>{data[id].time}</td>
                  <td>{data[id].message}</td>
                  <td>
                    {/* <Link to={`/update/${id}`}>
                    <button className="btn btn-edit">Edit</button>
                  </Link> */}
                    <button
                      className="btn btn-delete m-1"
                      onClick={() => onDelete(id)}
                    >
                      <i className="fa fa-trash text-danger"></i>
                    </button>
                    <Link to={`awd/view/${id}`}>
                      <button
                        className="btn btn-view m-1"
                        data-toggle="modal"
                        data-target="#exampleModalCenter"
                      >
                        <i className="fa fa-eye text-primary"></i>
                      </button>
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Awd;
