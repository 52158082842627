import React from "react";
import { Link } from "react-router-dom";

const ThankYou = () => {
  return (
    <div className="jumbotron text-center">
      <h1 className="display-4 text-success">Thank You!</h1>
      <p className="lead">
        <strong>Please check your email</strong> for further instructions we
        will contact you as soon as possible.
      </p>
      <p className="lead">
        For any other queries Contact us : <br /> <i className="fa fa-phone"> </i>{" "}
        737 850 0800 , 737 860 0800
      </p>
      <p className="lead py-3">
        <Link className="btn btn-primary btn-sm" to="/" role="button">
          Continue to homepage
        </Link>
      </p>
      <div className=" center">
        <span>Follow us</span>
        <span className="px-2">
          <a
            className="px-3 justify-content-between"
            href="https://www.facebook.com/Khidmatfoundationnagpur/"
          >
            <i className="fab fa-facebook-f"></i>
          </a>
          <a
            className="px-3 justify-content-between"
            href="https://www.instagram.com/khidmat_foundation_nagpur/"
          >
            <i className="fab fa-instagram"></i>
          </a>
          <a
            className="px-3 justify-content-between"
            href="https://www.youtube.com/channel/UC2ayb2ROklHGbUvbNyaWhcw"
          >
            <i className="fab fa-youtube"></i>
          </a>
          <a
            className="px-3 justify-content-between"
            href="https://chat.whatsapp.com/C0moBvA2Dh7Ff9nkd7bTj8"
          >
            <i className="fab fa-whatsapp"></i>
          </a>
          <a
            className="px-3 justify-content-between"
            href="https://www.twitter.com/Khidmatfoundationnagpur/"
          >
            <i className="fab fa-twitter"></i>
          </a>
        </span>
      </div>
    </div>
  );
};

export default ThankYou;
