import data from "../data/data.json";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebase";

const Gallery = () => {
  const [GalleryView, setGalleryView] = useState([]);
  useEffect(() => {
    const GalleryViewRef = collection(db, "Gallery");
    const q = query(GalleryViewRef, orderBy("createdAt", "desc"));
    onSnapshot(q, (snapshot) => {
      const GalleryView = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setGalleryView(GalleryView);
    });
  }, []);
  return (
    <>
      <div id="gallery" className="gallery">
        <div className="container p-sm-0">
          <div className="row">
            <div className="gallery-filter d-none d-sm-block">
              <button
                className="btn btn-default filter-button mx-2"
                data-filter="all"
              >
                All
              </button>

              {data.image.map(({ button, id }) => (
                <button
                  className="btn btn-default filter-button mx-2"
                  data-filter={button}
                  key={id}
                >
                  {button}
                </button>
              ))}
            </div>
            <br />

            {/* {data.image.map(({ url, button }) => (
              <div
                className={`gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter ${button}`}
              >
                <img
                  src={url}
                  alt=""
                  className="img-responsive img-thumbnail card rounded-lg"
                />
              </div>
            ))} */}

            {GalleryView.map(({ id, image, button }) => (
              <div
                key={id}
                className={`gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter ${button}`}
              >
                <img
                  src={image}
                  alt=""
                  className="img-responsive img-thumbnail card rounded-lg"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
