import React from "react";

const FourZreoFour = () => {
  return (
    <>
      <div className="container _404">
        <div className="row vh-100 bg-light rounded align-items-center justify-content-center">
          <div className="col-md-12 text-center _404">
            <i
              className="fa fa-frown text-gray"
              style={{ fontSize: "150px" }}
            ></i>
            <h1 className="text">404</h1>
            <h1 className="text-muted sans-serif">Page Not Found <i
              className="fa fa-exclamation-triangle text-muted"
              style={{ fontSize: "30px" }}
            ></i></h1>
            <h4 className="text-muted">
              We’re sorry, the page you have looked for does not exist in our
              website!
            </h4>
            <a className="btn btn-success rounded-pill _404btn _404" href="/">
              Go Back To Home
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default FourZreoFour;
