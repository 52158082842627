import React from "react";
import { useEffect, useState } from "react";
import fireDb from "./firebase";
import { Link, useParams } from "react-router-dom";

const View = () => {
  const [user, setUser] = useState({});

  const { id } = useParams();

  useEffect(() => {
    fireDb
      .child(`appointment/${id}`)
      .get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          setUser({ ...snapshot.val() });
        } else {
          setUser({});
        }
      });
  }, [id]);

  // console.log("user", user);
  return (
    <>
      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Appointment Details
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="modal-content">
                <div className="card">
                  <div className="card-header">
                    <strong>ID: </strong>
                    <span>{id}</span>
                  </div>
                  <div className="container p-3">
                    <strong>Name: </strong>
                    <span>
                      {user.name} {user.sirname}
                    </span>
                    <br />
                    <br />

                    <strong>Contact: </strong>
                    <span>{user.contact}</span>
                    <br />
                    <br />

                    <strong>Email: </strong>
                    <span>{user.email}</span>
                    <br />
                    <br />

                    <strong>Date: </strong>
                    <span>{user.date}</span>
                    <br />
                    <br />

                    <strong>Time: </strong>
                    <span>{user.time}</span>
                    <br />
                    <br />

                    <strong>Message: </strong>
                    <span>{user.message}</span>
                    <br />
                    <br />

                    <strong>Appointment Booking: </strong>
                    <span>{user.current}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <Link to="/awd" type="button" className="btn btn-secondary">
                Go Back
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default View;
