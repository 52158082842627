import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import ThankYou from "./ThankYou";

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  let name, value;
  const getUserData = (event) => {
    name = event.target.name;
    value = event.target.value;

    setUser({ ...user, [name]: value });
  };

  const postData = async (e) => {
    setLoading(false);
    e.preventDefault();

    const { name, email, phone, message } = user;

    if (name && email && phone && message) {
      const res = await fetch(
        "https://sunni-mohalla-clinic-default-rtdb.firebaseio.com/feedback.json",
        // "https://khidmat-foundation-default-rtdb.firebaseio.com/feedback.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            email,
            phone,
            message,
          }),
        }
      );
      setLoading(false);

      if (res) {
        setUser({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
        // alert("Your feedback is much appreciated");
        toast.success("Your feedback is much appreciated ❤️");
        setLoading(true);
      }
    } else {
      // alert("Please fill all inputs");
      toast.error("“Please fill out all required fields”");
      setLoading(false);
    }
  };
  return (
    <>
      {loading && <ThankYou />}
      <section id="contact_us" className="contact-us-single">
        <div className="row no-margin">
          <div className="col-sm-6 no-padding">
            <iframe
              className="w-100"
              title="googlemap"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3721.8442839899744!2d79.14347701488494!3d21.118773385949705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4b971b1844705%3A0xe969f2cf88421401!2sKhidmat%20Foundation%20Nagpur!5e0!3m2!1sen!2sin!4v1636807154400!5m2!1sen!2sin"
              height="480"
              frameBorder="0"
              allowFullScreen=""
              loading="lazy"
            />
          </div>
          <div className="col-sm-6 cop-ck">
            <h2 className="text-primary">Feedback </h2>
            <form action="" method="post">
              <div className="row cf-ro">
                <div className="col-sm-3">
                  <label>Name :</label>
                </div>
                <div className="col-sm-8">
                  <input
                    onChange={getUserData}
                    value={user.name}
                    className="form-control input-sm"
                    placeholder="Enter Name"
                    autoComplete="off"
                    type="text"
                    name="name"
                    required
                  />
                </div>
              </div>
              <div className="row cf-ro">
                <div className="col-sm-3">
                  <label>Email :</label>
                </div>
                <div className="col-sm-8">
                  <input
                    onChange={getUserData}
                    value={user.email}
                    className="form-control input-sm"
                    placeholder="Enter Email Address"
                    autoComplete="off"
                    type="email"
                    name="email"
                    required
                  />
                </div>
              </div>
              <div className="row cf-ro">
                <div className="col-sm-3">
                  <label>Mobile :</label>
                </div>
                <div className="col-sm-8">
                  <input
                    onChange={getUserData}
                    value={user.phone}
                    className="form-control input-sm"
                    placeholder="Enter Mobile Number"
                    autoComplete="off"
                    type="number"
                    name="phone"
                    required
                  />
                </div>
              </div>
              <div className="row cf-ro">
                <div className="col-sm-3">
                  <label>Message :</label>
                </div>
                <div className="col-sm-8">
                  <textarea
                    onChange={getUserData}
                    value={user.message}
                    rows="5"
                    placeholder="Enter Your Message"
                    className="form-control input-sm"
                    autoComplete="off"
                    type="text"
                    name="message"
                    required
                  ></textarea>
                </div>
              </div>
              <div className="row cf-ro">
                <div className="col-sm-3">
                  <label></label>
                </div>
                <div className="col-sm-8">
                  <button className="btn btn-success btn-sm" onClick={postData}>
                    Send Message
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
