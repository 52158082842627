import React from "react";

const AboutHospital = () => {
  return (
    <>
      <section id="about_us" className="with-medical">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <img src="assets/images/why.jpg" alt="" />
            </div>
            <div className="col-lg-6 col-md-12 txtr text-justify">
              <h4>
                About Our Health Care Center <br />
                <span>Khidmat Foundation</span>
              </h4>
              <p>
                Sunni Mohalla Clinic is a charitable, multi-specialty hospital
                located at Roshan Bag Kharbi Nagpur India. Established in 14
                April, 2021 & today it is a best charitable hospital in our
                society with there Branches. Khidmat Foundation Hospital offers
                state-of-the-art diagnostic, therapeutic and intensive care
                facilities in a one-stop medical center.
              </p>
              <p>
                Health Care Center has developed its Maternity and Gynaecology
                services to meet the needs of local women and their families. We
                provide a tertiary (specialised care) service for women with
                specialist needs in fetal/maternal medicine and we are a
                regional centre for Gynaecology. We also provide a community
                midwifery service and in some local health clinics provide
                family planning services. <a href="/about"> Read more...</a>{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutHospital;
