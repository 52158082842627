import React from "react";
import { GalleryAdd } from "./GalleryAdd";
import { GalleryView } from "./GalleryView";

export const AddGallery = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <GalleryView />
          </div>
          <div className="col-md-3 mt-5 pt-4">
            <GalleryAdd />
          </div>
        </div>
      </div>
    </>
  );
};
