import { addDoc, collection, Timestamp } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { db, storage } from "../firebase";

const options = [
  { value: "Hospital", label: "Hospital" },
  { value: "Reception", label: "Reception" },
  { value: "Activity", label: "Activity" },
  { value: "Check-up-Camp", label: "Check-up-Camp" },
];

export const GalleryAdd = () => {
  const [formData, setFormData] = useState({
    image: "",
    button: "",
    createdAt: Timestamp.now().toDate(),
  });

  const [progress, setProgress] = useState(0);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const handlePublish = () => {
    if (!formData.button || !formData.image) {
      toast("Please Choose the Image !", { type: "info" });
      return;
    }

    const storageRef = ref(
      storage,
      `/Gallery/${Date.now()}${formData.image.name}`
    );
    const uploadImage = uploadBytesResumable(storageRef, formData.image);

    uploadImage.on(
      "state_changed",
      (snapshot) => {
        const progressPresent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progressPresent);
      },
      (err) => {
        console.log(err);
      },
      () => {
        setFormData({
          image: "",
          button: "",
        });
        getDownloadURL(uploadImage.snapshot.ref).then((url) => {
          const articleRef = collection(db, "Gallery");
          addDoc(articleRef, {
            button: formData.button,
            image: url,
            createdAt: Timestamp.now().toDate(),
          })
            .then(() => {
              toast("Your Image is Publish.", { type: "success" });
              setProgress(0);
            })
            .catch((err) => {
              toast("Error found in adding", { type: "error" });
            });
        });
      }
    );
  };
  return (
    <div className="border p-3 bg-white mt-5">
      <h4>Create Gallery</h4>

      <label htmlFor="">Select Category</label>
      <select
        name="button"
        className="form-control"
        onChange={(e) => handleChange(e)}
      >
        {options.map((option) => (
          <option value={option.value}>{option.label}</option>
        ))}
      </select>

      <label htmlFor="">Select Image</label>
      <input
        type="file"
        name="image"
        accept="image/*"
        onChange={(e) => handleImageChange(e)}
      />

      <br />
      {progress === 0 ? null : (
        <div className="progress mt-3">
          <div className="progress-bar progress-bar-striped" style={{ width: `${progress}%` }}>
            {`uploading image ${progress}%`}
          </div>
        </div>
      )}

      <br />
      <button
        className="btn btn-primary my-4 px-5 col-12"
        onClick={handlePublish}
      >
        Publish
      </button>

      <br />
    </div>
  );
};
