import React from 'react'
import AddBlogs from './Blogs'
import { AddGallery } from './Gallery'

const BackendApi = () => {
  return (
    <>
    <div className="scroll-y">
    <AddGallery />
    <AddBlogs />
    </div>
    </>
  )
}

export default BackendApi