import React from "react";

const Slider = () => {
  return (
    <>
      <div className="slider container-fluid">
        <ul id="slider">
          <li
            data-active="1"
            data-background="assets/images/slider/slider_1.jfif"
          >
            <div className="slider-layer">
              <div className="container">
                <h1 className="animated  fadeInRightBig ">
                  Welcome to our Hospital <br /> We help you & your Family
                  <br />
                </h1>
                <p className="animated  fadeInLeftBig">
                  Our hospital are usually funded by donations and special
                  contributions. <br />
                  This is a charitable, non-profit hospital that provides
                  treatment <br />
                  for the poor peoples who can't afford medical treatment.{" "}
                </p>
                <div className="animated fadeInUpBig buttn-row">
                  <div className="btn">
                    {" "}
                    <a href="/about"> Read more</a>
                  </div>
                  <div
                    data-toggle="modal"
                    data-target="#myModal"
                    className="btn"
                  >
                    Donate Now
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li
            data-active="0"
            data-background="assets/images/slider/slider_2.jfif"
          >
            <div className="slider-layer">
              <div className="container">
                <h1 className="animated  slideInDown ">
                  Welcome to our Hospital <br /> We help you & your Family{" "}
                  <br />
                </h1>
                <p className="animated  zoomInLeft ">
                  Our hospital are usually funded by donations and special
                  contributions. <br />
                  This is a charitable, non-profit hospital that provides
                  treatment <br />
                  for the poor peoples who can't afford medical treatment.{" "}
                </p>
                <div className="buttn-row animated  zoomInLeft">
                  <div className="btn">
                    {" "}
                    <a href="/about"> Read more</a>
                  </div>
                  <div
                    data-toggle="modal"
                    data-target="#myModal"
                    className="btn"
                  >
                    Donate Now
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li
            data-active="2"
            data-background="assets/images/slider/slider_3.jfif"
          >
            <div className="slider-layer">
              <div className="container">
                <h1 className="animated  slideInDown ">
                  Welcome to our Hospital <br /> We help you & your Family{" "}
                  <br />
                </h1>
                <p className="animated  zoomInLeft ">
                  Our hospital are usually funded by donations and special
                  contributions. <br />
                  This is a charitable, non-profit hospital that provides
                  treatment <br />
                  for the poor peoples who can't afford medical treatment.{" "}
                </p>
                <div className="buttn-row animated  zoomInLeft">
                  <div className="btn">
                    {" "}
                    <a href="/about"> Read more</a>
                  </div>
                  <div
                    data-toggle="modal"
                    data-target="#myModal"
                    className="btn"
                  >
                    Donate Now
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li
            data-active="3"
            data-background="assets/images/slider/slider_4.jfif"
          >
            <div className="slider-layer">
              <div className="container">
                <h1 className="animated  slideInDown ">
                  Welcome to our Hospital <br /> We help you & your Family{" "}
                  <br />
                </h1>
                <p className="animated  zoomInLeft ">
                  Our hospital are usually funded by donations and special
                  contributions. <br />
                  This is a charitable, non-profit hospital that provides
                  treatment <br />
                  for the poor peoples who can't afford medical treatment.{" "}
                </p>
                <div className="buttn-row animated  zoomInLeft">
                  <div className="btn">
                    {" "}
                    <a href="/about"> Read more</a>
                  </div>
                  <div
                    data-toggle="modal"
                    data-target="#myModal"
                    className="btn"
                  >
                    Donate Now
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li
            data-active="4"
            data-background="assets/images/slider/slider_5.jfif"
          >
            <div className="slider-layer">
              <div className="container">
                <h1 className="animated  slideInDown ">
                  Welcome to our Hospital <br /> We help you & your Family{" "}
                  <br />
                </h1>
                <p className="animated  zoomInLeft ">
                  Our hospital are usually funded by donations and special
                  contributions. <br />
                  This is a charitable, non-profit hospital that provides
                  treatment <br />
                  for the poor peoples who can't afford medical treatment.{" "}
                </p>
                <div className="buttn-row animated  zoomInLeft">
                  <div className="btn">
                    {" "}
                    <a href="/about"> Read more</a>
                  </div>
                  <div
                    data-toggle="modal"
                    data-target="#myModal"
                    className="btn"
                  >
                    Donate Now
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li
            data-active="5"
            data-background="assets/images/slider/slider_6.jfif"
          >
            <div className="slider-layer">
              <div className="container">
                <h1 className="animated  slideInDown ">
                  Welcome to our Hospital <br /> We help you & your Family{" "}
                  <br />
                </h1>
                <p className="animated  zoomInLeft ">
                  Our hospital are usually funded by donations and special
                  contributions. <br />
                  This is a charitable, non-profit hospital that provides
                  treatment <br />
                  for the poor peoples who can't afford medical treatment.{" "}
                </p>
                <div className="buttn-row animated  zoomInLeft">
                  <div className="btn">
                    {" "}
                    <a href="/about"> Read more</a>
                  </div>
                  <div
                    data-toggle="modal"
                    data-target="#myModal"
                    className="btn"
                  >
                    Donate Now
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Slider;
