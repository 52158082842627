import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Header from "./Components/Header";
import Slider from "./Components/Slider";
import Services from "./Components/Services";
import AboutHospital from "./Components/AboutHospital";
import Blogs from "./Components/Blogs";
import Gallery from "./Components/Gallery";
// import Specialists from "./Components/Specialists";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import About from "./Components/About";
import Appointment from "./Components/Appointment";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Awd from "./admin/Awd";
import View from "./admin/View";
import Feedback from "./admin/Feedback";
// import Home from "./Components/Home";
import FourZreoFour from "./Components/FourZreoFour";
// import FormValidation from "./Components/Form";
import BackendApi from "./api/BackendApi";

function App() {
  return (
    <>
      <Router>
        <ToastContainer position="top-center" />
        <Switch>
          <Route exact path="/">
            <Header />
            <Slider />
            <Services />
            <AboutHospital />
            <Blogs />
            <Gallery />
            {/* <Specialists /> */}
            <Contact />
            <Footer />
          </Route>
          <Route exact path="/about" component={About} />
          {/* <Route exact path="/e" component={FormValidation} /> */}
          <Route exact path="/appointment" component={Appointment} />
          <Route exact path="/awd" component={Awd} />
          <Route exact path="/awd/view/:id" component={View} />
          <Route exact path="/feedback" component={Feedback} />
          <Route exact path="/api" component={BackendApi} />
          <Route path="*" component={FourZreoFour} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
