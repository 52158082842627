// import firebase from "firebase/app";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBm_VKiPWXDRLXAyNwgJjykvkMqtQHCkTE",
  authDomain: "sunni-mohalla-clinic.firebaseapp.com",
  databaseURL: "https://sunni-mohalla-clinic-default-rtdb.firebaseio.com",
  projectId: "sunni-mohalla-clinic",
  storageBucket: "sunni-mohalla-clinic.appspot.com",
  messagingSenderId: "201457029",
  appId: "1:201457029:web:9279b87d8634607606d8ec",
  measurementId: "G-701BFE44G8",
};

const fireDb = firebase.initializeApp(firebaseConfig);
export default fireDb.database().ref();
