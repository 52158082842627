import React from "react";
import BlogsPost from "./BlogsView";
import BlogsPostAdd from "./BlogsAdd";

const AddBlogs = () => {
  return (
    <>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-8">
            <BlogsPost />
          </div>
          <div className="col-md-4 my-5 py-4">
            <BlogsPostAdd />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBlogs;
