import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import DeleteBlog from "./DeleteBlog";

const BlogsPost = () => {
  const [BlogsPost, setBlogsPost] = useState([]);
  useEffect(() => {
    const BlogsPostRef = collection(db, "BlogsPost");
    const q = query(BlogsPostRef, orderBy("createdAt", "desc"));
    onSnapshot(q, (snapshot) => {
      const BlogsPost = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBlogsPost(BlogsPost);
      console.log(BlogsPost);
    });
  }, []);
  return (
    <>
      <div className="my-5 hed">
        <h3>Add Blogs</h3>
      </div>

      {BlogsPost.length === 0 ? (
        <p className="p-5">
          <div class="spinner-border m-auto" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </p>
      ) : (
        <div className="scroll-y">
          {BlogsPost.map(
            ({
              id,
              title,
              description,
              image,
              comment,
              view,
              admin,
              url,
              createdAt,
            }) => (
              <div className="card mb-5 col-sm-12" key={id}>
                <div className="d-inline-flex">
                  <div className="col-sm-4 m-auto">
                    <img alt="" src={image} />
                  </div>

                  <div className="col-sm-8 m-auto py-3">
                    <h5>{title}</h5>
                    <p>{description}</p>
                    <div className="topp blog-topp my-2">
                      <a href="/" className="comment">
                        <i className="fa fa-comments mx-2"> </i>
                        {comment} Comments
                      </a>
                      <a href="/" className="views">
                        <i className="fa fa-eye mx-2"> </i>
                        {view} Views
                      </a>
                      <a href="/" className="admin">
                        <i className="fa fa-user mx-2"> </i>
                        {admin} Post
                      </a>

                      <span className="float-right">
                        <i className="fa fa-calendar mx-2"></i>
                        {createdAt.toDate().toDateString()}
                      </span>
                    </div>
                    <DeleteBlog id={id} image={image} />
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      )}
    </>
  );
};

export default BlogsPost;
