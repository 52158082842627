import React from "react";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import fireDb from "../admin/firebase";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import firebase from "firebase/compat/app";
import Header from "./Header";
import Footer from "./Footer";

const initialState = {
  name: "",
  sirname: "",
  contact: "",
  email: "",
  date: "",
  time: "",
  message: "",
  current: firebase.firestore.Timestamp.now().toDate().toString(),
};

const Appointment = () => {
  const current = firebase.firestore.Timestamp.now().toDate().toString();

  const [state, setState] = useState(initialState);
  const [data, setData] = useState({});

  const { name, sirname, contact, email, date, time, message } = state;

  const history = useHistory();

  const { id } = useParams();

  useEffect(() => {
    fireDb.child("appointment").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setData({ ...snapshot.val() });
      } else {
        setData({});
      }
    });
    return () => {
      setData({});
    };
  }, [id]);

  useEffect(() => {
    if (id) {
      setState({ ...data[id] });
    } else {
      setState({ ...initialState });
    }
    return () => {
      setState({ ...initialState });
    };
  }, [id, data]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name || !sirname || !contact || !email || !date || !time || !message) {
      toast.error("Please fill all input field !");
    } else {
      if (!id) {
        fireDb.child("appointment").push(state, (err) => {
          if (err) {
            toast.error(err);
          } else {
            toast.success("Your appointment is booked.");
          }
        });
      } else {
        fireDb.child(`appointment/${id}`).set(state, (err) => {
          if (err) {
            toast.error(err);
          } else {
            toast.success("Contact Updated Success");
          }
        });
      }
      setTimeout(() => history("/"), 500);
    }
  };

  return (
    <>
      <Header />
      <div className="container mt-lg-1 pt-lg-1 mb-lg-4 pb-lg-4">
        <span className="pt-2 text-center d-md-block d-none">{current}</span>
        <div className="row py-lg-4">
          <div className="col-md-6 offset-md-3 border p-4 shadow bg-white">
            <div className="col-12">
              <h4 className="fw-normal text-info fs-4 mb-4">
                Appointment With Doctor
              </h4>
            </div>
            <form action="/" onSubmit={handleSubmit}>
              <div className="row g-3">
                <div className="col-md-6">
                  <label>First Name </label>
                  <input
                    name="name"
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    value={name || ""}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6">
                  <label>Last Name </label>
                  <input
                    name="sirname"
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    value={sirname || ""}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6">
                  <label>Mobile </label>
                  <input
                    name="contact"
                    type="number"
                    className="form-control"
                    placeholder="Phone Number"
                    value={contact || ""}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6">
                  <label>Email </label>
                  <input
                    name="email"
                    type="email"
                    className="form-control"
                    placeholder="Enter Email"
                    value={email || ""}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6">
                  <label>Schedule Date </label>
                  <input
                    name="date"
                    type="date"
                    className="form-control"
                    placeholder="Enter Date"
                    value={date || ""}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6">
                  <label>Schedule Time </label>
                  <input
                    name="time"
                    type="time"
                    className="form-control"
                    placeholder="Enter Time"
                    value={time || ""}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="col-12">
                  <label>Mention your problem </label>
                  <textarea
                    name="message"
                    className="form-control"
                    placeholder="Message"
                    value={message || ""}
                    onChange={handleInputChange}
                  ></textarea>
                </div>
                <div className="col-12">
                  <input
                    name="current"
                    type="hidden"
                    value={current || ""}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-12 mt-5">
                  <Link
                    to="/?"
                    type="button"
                    className="btn btn-outline-secondary float-left me-2"
                  >
                    Cancel
                  </Link>
                  <button type="submit" className="btn btn-primary float-right">
                    Book Appointment
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Appointment;
