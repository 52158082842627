import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebase";

const Blogs = () => {
  const [BlogsPost, setBlogsPost] = useState([]);
  useEffect(() => {
    const BlogsPostRef = collection(db, "BlogsPost");
    const q = query(BlogsPostRef, orderBy("createdAt", "desc"));
    onSnapshot(q, (snapshot) => {
      const BlogsPost = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBlogsPost(BlogsPost);
    });
  }, []);
  return (
    <>
      <div id="blog" className="blog">
        <div className="row session-title">
          <h2 className="text-primary">Latest Blog</h2>
          <p className="text-success">
            Please read the following facts in order to make sure of all pluses
            of our clinic
          </p>
        </div>
        <div className="container">
          <div className="row">
            {BlogsPost.map(
              ({
                id,
                title,
                description,
                image,
                comment,
                view,
                admin,
                url,
                createdAt,
              }) => (
                <div className="col-md-6 col-sm-12" key={id}>
                  <div className="blog-singe no-margin row">
                    <div className="col-sm-5 blog-img-tab">
                      <img src={image} alt="" />
                    </div>
                    <div className="col-sm-7 blog-content-tab">
                      <h2>{title}</h2>
                      <p>
                        <i className="fas fa-user">
                          <small>{admin}</small>
                        </i>{" "}
                        <i className="fas fa-eye">
                          <small>({view})</small>
                        </i>{" "}
                        <i className="fas fa-comments">
                          <small>({comment})</small>
                        </i>
                      </p>
                      <p className="blog-desic">{description}</p>
                      <a target="_blank" rel="noreferrer" href={url}>
                        Read More <i className="fas fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Blogs;
